@import '~styles/utils';
$slide-size: 68px;

.predefined-images-slider {
    margin: 20px 0 0;

    &__slide {
        width: $slide-size !important;
        height: $slide-size;
        outline: none;

        &--small {
            width: 88px !important;
            height: 68px;
        }
    }

    &__image {
        height: 100%;
        margin-right: 16px;

        &--last {
            margin-right: 0;
        }
    }

    :global(.slick-list) {
        height: $slide-size;
    }

    :global(.slick-disabled) {
        display: none;
    }
}

.predefined-images-loading {
    position: relative;
    top: -38px;
    width: 40px;
    height: 40px;

    &__loading-element {
        width: 32px;
        height: 32px;
        border-color: $white transparent transparent transparent;
    }
}
