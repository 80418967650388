@import '~styles/utils';

$transition-time: 300ms;

.style-option {
    border: 1px solid $input-border-color;
    border-left-width: 0;
    display: flex;
    cursor: pointer;
    transition-duration: $transition-time;

    &__bookmark {
        width: 10px;
        opacity: 0.6;

        &--active {
            opacity: 1;
        }
    }

    &--scale-OS_EXPLORER {
        border-color: $orange;
        background-color: rgba($orange, 0.05);

        .style-option__bookmark {
            background-color: $orange;
        }

        &:hover {
            border-color: $orange;
            transition-duration: $transition-time;
        }
    }

    &--scale-OS_LANDRANGER {
        border-color: $pink;
        background-color: rgba($pink, 0.05);

        .style-option__bookmark {
            background-color: $pink;
        }

        &:hover {
            border-color: $pink;
            transition-duration: $transition-time;
        }
    }

    &__image {
        max-width: 90px;
        min-width: 90px;
        min-height: 90px;
    }

    &__description {
        margin-top: 10px;
        padding-left: 8px;
        padding-right: 4px;
        width: auto;
        font-size: 15px;

        @include breakpoint(xsmall) {
            margin-left: 8px;
            width: 212px;
        }
    }

    &__description-first-line {
        @include text-semi-bold();
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 5px;
    }

    &__description-second-line {
        color: $charcoal-light;
        font-weight: 400;
        font-size: 14px;
        max-width: 80%;
        margin-bottom: 5px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &--inactive {
        transition-duration: $transition-time;
        background-color: transparent;
        border-color: $border-grey;
    }
}
