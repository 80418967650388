@import '~styles/utils';

.area-and-style-tab {
    &__title {
        @include title();
        margin-top: 12px;
        margin-bottom: 15px;
    }

    &__column {
        display: block;
    }

    &__tile {
        margin-bottom: 10px;
    }

    &__tile-content {
        display: flex;
        padding: 20px 15px;

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__icon {
            padding-right: 15px;
        }

        &__title {
            color: $charcoal-light;
            font-size: 16px;
            font-weight: 600;
        }

        &__description {
            color: $charcoal-light;
            font-size: 14px;
            font-weight: 400;
        }
    }
}
