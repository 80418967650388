@import '~styles/utils';

.user-info-modal {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;

    &__header {
        @include modal-title();
        margin-left: 10px;
    }

    &__content {
        @include modal-subtitle();
    }

    &__error-warning {
        @include os-icon('error');
        color: #c63d54;
        font-size: 24px;
    }

    &__success-icon {
        @include os-icon('tick');
        color: #4bb543;
        font-size: 24px;
    }
}
