@import '~styles/utils';

.product-preview-map {
    &__loading {
        width: 40px;
        height: 40px;
    }

    &__loading-element {
        width: 32px;
        height: 32px;
        border-color: $black transparent transparent transparent;
    }
}
