@import '~styles/utils';

.image-controls {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium up) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &__control {
        display: flex;
        align-items: center;
        cursor: pointer;

        &--rotate-control {
            @include os-icon(rotate);
            color: $berry;
            font-size: 24px;

            &::before {
                margin-top: 4px;
            }

            .image-controls__button {
                font-size: 16px;
            }
        }
    }

    &__button {
        color: $berry;
        margin-left: 5px;
    }
}
