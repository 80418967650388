@import '~styles/utils';

@mixin slider-arrow-overlay($direction) {
    opacity: 1;
    background-image: linear-gradient($direction, rgba(0, 0, 0, 0), $shadow);
}

.slider-arrow {
    display: flex;
    align-items: center;
    width: 42px;
    height: 100%;
    z-index: 1;
    font-size: 20px;
    line-height: 20px;
    opacity: 0;

    &::before {
        color: $white;
    }

    &--prev {
        @include os-icon('left-arrow-large');

        left: 0;
        justify-content: flex-start;

        @include breakpoint(small down) {
            @include slider-arrow-overlay(to left);
        }

        &:hover {
            @include slider-arrow-overlay(to left);
        }
    }

    &--next {
        @include os-icon('right-arrow-large');

        right: 0;
        justify-content: flex-end;

        @include breakpoint(small down) {
            @include slider-arrow-overlay(to right);
        }

        &:hover {
            @include slider-arrow-overlay(to right);
        }
    }
}
