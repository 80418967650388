@import '~styles/utils';

.custom-scale-line-inner {
    @include ol-scale-line-inner();

    width: 89px;
    border: 0 solid;
    border-bottom-width: 1px;

    &::after {
        @include absolute($bottom: 0, $right: 0);
    }
}

.custom-scale-line-imperial-inner {
    @include ol-scale-line-inner();

    width: 58px;

    &::after {
        @include absolute($top: 0, $right: 0);
        content: '';
    }
}

.custom-scale-line-inner-zoom {
    @include ol-scale-line-inner();

    width: 143px;
    border: 0 solid;
    border-bottom-width: 1px;

    &::after {
        @include absolute($bottom: 0, $right: 0);
    }
}

.custom-scale-line-imperial-inner-zoom {
    @include ol-scale-line-inner();

    width: 87px;

    &::after {
        @include absolute($top: 0, $right: 0);
        content: '';
    }
}
