@import '~styles/utils';

.tool {
    &__container {
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin: 15px 0;
        cursor: pointer;
    }

    &__icon {
        margin-right: 10px;
    }

    &__separator {
        margin: 0;
        height: 1px;
        border: 0;
        background: $light-grey;
    }

    &__mb20 {
        margin-right: 20px;
    }
}
