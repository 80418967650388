@import '~styles/utils';

.break-holding-page {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 10%;
    padding: 30px;

    &__text {
        font-size: 1.5rem;
    }
}
