@import '~styles/utils';

$border-radius: 12px;

.modal {
    min-width: 200px;
    max-width: 400px;
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: column;
    outline: 0;
    max-height: 95vh;
    box-shadow: $shadow-light 0 2px 6px 0;
    border-radius: $border-radius;
    margin-right: 10px;

    @include breakpoint(large) {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    @include breakpoint(large) {
        display: block;
    }

    &--mobile {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        margin-right: 0;
    }

    &__content {
        padding-right: 10px;
        padding-left: 10px;
    }

    &__header {
        width: 100%;
        position: relative;
        line-height: 30px;
        min-height: 30px;
        padding: 20px;

        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: inherit;
            display: inline-block;
            color: $charcoal-light;
        }

        &__subtitle {
            padding-top: 5px;
            color: $charcoal-light;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            @include breakpoint(medium) {
                width: 280px;
            }
        }

        &__close-button {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
            background: transparent;
            border: 0;
            transition: all 0.25s ease-in-out;

            &:focus-visible {
                border: 1px solid $black;
            }
        }
    }

    &__content {
        padding: 20px;
        padding-bottom: 0;
        padding-top: 0;
        width: 100%;
        overflow-y: auto;
    }
}
