@import '~styles/utils';

.sidebar {
    width: 100%;
    height: 75%;
    z-index: 1;

    @include breakpoint(medium) {
        height: 50%;
    }

    @include breakpoint(large) {
        min-height: 100%;
        max-width: 360px;
        box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.2);
        order: 1;
    }

    &__slide {
        // disable JS transformation
        transform: none !important;
    }

    &__inner {
        width: 100%;
        height: 100%;
        background-color: $white;
    }
}
