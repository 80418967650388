$transition-time: 300ms;

@mixin button-base() {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

@mixin primary-button() {
    @include button-base();
    padding: 8px 20px;
    background-color: $berry;
    border: 1px solid $berry;
    color: $white;

    &:hover {
        background-color: $berry-dark;
        transition-duration: $transition-time;
    }
}

@mixin secondary-button() {
    @include button-base();
    @include text-semi-bold();

    padding: 8px 20px;
    border: 1px solid $berry;
    color: $berry;

    &:hover {
        background-color: $clouds;
    }
}

@mixin disabled-button() {
    @include button-base();

    padding: 8px 20px;
    border: 1px solid lighten($berry, 50%);
    color: $white;
    background-color: lighten($berry, 50%);
    cursor: default;
}

@mixin link-button() {
    @include button-base();
    color: $berry;

    &:hover {
        color: $berry-dark;
    }

    &:focus {
        color: $black;
    }
}
