@import '~styles/utils';

.tip {
    display: flex;
    font-size: 14px;
    color: $black;
    margin-bottom: 14px;

    b {
        color: $berry;
        font-weight: normal;
    }

    div {
        margin-left: 15px;
    }
}
