@import './utils';

@import './modules/fonts';
@import './modules/typography';

html {
    box-sizing: border-box;
    font-size: $global-font-size;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    background: $body-background;
    font-family: $body-font-family, sans-serif;
    line-height: $global-line-height;
    color: $body-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input {
    &::-ms-clear {
        display: none;
    }
}

.reactour__helper {
    *[data-tour-elem='badge'] {
        background-color: $berry;
    }

    *[data-tour-elem='controls'] {
        display: none;
    }
}

.reactour__close {
    width: 16px !important;
    height: 16px !important;
}

.slick-slider {
    .slick-list {
        cursor: grab;
    }

    .slick-next {
        opacity: 1;
        background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
        ) !important;
    }

    .slick-prev {
        opacity: 1;
        background-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
        ) !important;
    }
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.BrainhubCarousel__arrow--disable {
    display: none;
}

.BrainhubCarousel {
    @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        transform: scale(0.8) translateY(-40px);
    }
}

.BrainhubCarousel__customArrows {
    margin-top: -46px;
}

@include breakpoint(small down) {
    .BrainhubCarouselItem {
        transform: scale(0.7);
    }

    .BrainhubCarouselItem:nth-of-type(1).BrainhubCarouselItem--active {
        margin-left: 27px !important;
    }

    .BrainhubCarouselItem:nth-of-type(2).BrainhubCarouselItem--active,
    .BrainhubCarouselItem:nth-of-type(3).BrainhubCarouselItem--active,
    .BrainhubCarouselItem:nth-of-type(4).BrainhubCarouselItem--active,
    .BrainhubCarouselItem:nth-of-type(5).BrainhubCarouselItem--active {
        margin-left: -15px !important;
    }
}
