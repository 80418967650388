@import '~styles/utils';

.product-description {
    &__description {
        @include text-semi-bold();
        padding-bottom: 5px;
        font-size: 18px;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    &__title-line {
        text-transform: uppercase;
    }

    &__title-line,
    &__subtitle-line {
        word-wrap: break-word;
    }

    &__edit-text-button {
        margin: 20px 0;
    }
}
