@import '~styles/utils';

.loading-indicator {
    @include absolute($top: 50%, $left: 50%);
    width: 26px;
    height: 26px;
    transform: translate(-50%, -50%);

    &__element {
        @include absolute();
        box-sizing: border-box;
        display: block;
        width: 20px;
        height: 20px;
        margin: 3px;
        border: 3px solid $white;
        border-radius: 50%;
        animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $white transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
