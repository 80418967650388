@mixin ol-scale-line-inner() {
    position: relative;
    color: $stone;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    will-change: contents, width;

    &::after {
        content: '';
        width: 2px;
        height: 6px;
        background: $stone;
    }
}
