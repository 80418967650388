@import '~styles/utils';

$transition-time: 300ms;

.navigation-bottom-bar-content {
    &__button-back {
        button {
            background-color: $pale-grey;
            color: $charcoal-light;
            border-width: 0;
            margin-bottom: 0;

            &:hover {
                background-color: $mist;
                box-shadow: none;
                transition-duration: $transition-time;
            }
        }
    }
}
