@import '~styles/utils';

@mixin base-bottom-bar() {
    width: 100%;
    padding-bottom: 5px;
    background-color: $white;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
}

.bottom-bar {
    @include base-bottom-bar();
    align-self: flex-end;
    font-size: 18px;
    transform: translate3d(0, 0, 0);
    align-self: center;

    button {
        @include text-semi-bold();
        border-radius: 0;
    }
}
