@import '~styles/utils';

$transition-time: 300ms;

.tile {
    background-color: $white;
    border: 1px solid $mist;
    color: $charcoal-light;
    transition-duration: $transition-time;
    display: flex;
    cursor: pointer;

    &--primary {
        &--active {
            background-color: rgba($berry, 0.05);
            border-color: $berry;
            color: $charcoal-light;
        }

        &:hover {
            background-color: rgba($berry, 0.05);
            border-color: $berry;
            color: $charcoal-light;
        }
    }

    &--secondary {
        &--active {
            background-color: rgba($orange, 0.05);
            border-color: $orange;
            color: $charcoal-light;
        }

        &:hover {
            background-color: rgba($orange, 0.05);
            border-color: $orange;
            color: $charcoal-light;
        }
    }

    &--third {
        &--active {
            background-color: rgba($foxglove, 0.05);
            border-color: $foxglove;
            color: $charcoal-light;
        }

        &:hover {
            background-color: rgba($foxglove, 0.05);
            border-color: $foxglove;
            color: $charcoal-light;
        }
    }

    &--error {
        border-color: $validation-error;
        transition-duration: $transition-time;
    }
}
