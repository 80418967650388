@import '~styles/utils';

$frame-border-size: 10px;
$controls-height-xsmall: 180px;
$controls-height-small: 150px;
$controls-height-xlarge: 100px;

.print-area-view {
    transform: translate(-50%, -50%);
    width: $map-size-small + $frame-border-size * 2;
    pointer-events: none;

    &__outer {
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
    }

    &--product-preview {
        @include absolute($top: 50%, $left: 50%);
        @include breakpoint(medium) {
            width: $map-size-big + $frame-border-size * 2;
        }
    }

    &--map {
        @include absolute($top: 50%, $left: 50%);

        @include breakpoint(small) {
            top: 50%;
            width: $map-size-big + $frame-border-size * 2;
        }

        @include breakpoint(medium) {
            top: 65%;
        }

        @include breakpoint(xlarge) {
            top: 50%;
        }
    }

    &__frame {
        border-left: $frame-border-size solid $white;
        border-right: $frame-border-size solid $white;
        box-shadow: 2px 2px 8px 2px $shadow;
    }

    &__controls {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: $white;
        height: $controls-height-xsmall;
        padding-top: 16px;
        min-height: $frame-border-size;
        pointer-events: all;
        overflow: hidden;

        @include breakpoint(xsmall) {
            &:first-child {
                padding: 0;
                height: auto;
            }

            &:last-child {
                padding-bottom: 5px;
            }
        }

        @include breakpoint(medium) {
            height: $controls-height-small;
        }

        @include breakpoint(xlarge) {
            height: $controls-height-xlarge;

            &:first-child {
                padding-top: 16px;
                height: $controls-height-xlarge;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        &--map {
            @include breakpoint(large down) {
                height: auto;
            }
        }
    }

    &__spacer {
        height: $controls-height-xsmall;
        margin-bottom: -#{$frame-border-size};

        @include breakpoint(small) {
            height: $controls-height-small;
        }

        &--map {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    &__scale-line {
        margin: 10px 0;
    }

    &__info-icon {
        @include os-icon('info-notification');

        display: inline;
        vertical-align: middle;
        font-size: 18px;
        color: $link-or-info;
        margin-right: 8px;
    }

    &__info-text {
        font-size: 14px;
        line-height: 24px;
        display: inline;
    }
}
