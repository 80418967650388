@import '~styles/utils';

$transition-time: 300ms;

.auto-zoom {
    &__container {
        margin-bottom: 8px;
        transition-duration: 300ms;
        background-color: transparent;
        border: 1px solid $border-grey;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 10px;

        &:hover {
            border-color: $berry;
            transition-duration: $transition-time;
        }

        &--active {
            border-color: $berry;
            background-color: rgba($berry, 10%);
        }
    }

    &__title {
        @include text-semi-bold();
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 5px;
    }

    &__description {
        color: $charcoal-light;
        font-weight: 400;
        font-size: 14px;
    }
}
