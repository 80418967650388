@import '~styles/utils';

.select-component {
    display: flex;

    [class$='-container'] {
        flex: 1;
    }

    [class$='-control'] {
        height: 54px;
        border: 1px solid $rock;
        box-shadow: none;
        border-radius: 0;

        &:hover {
            border: 1px solid $berry;
        }
    }

    [class$='-indicatorSeparator'] {
        display: none;
    }

    [class$='-Group'] {
        padding: 0;
        position: relative;

        &:first-child {
            margin-top: 10px;

            &::after {
                content: '';
                display: block;
                bottom: 0;
                width: 100%;
                height: 2px;
                background: $pale-grey;
                z-index: -1;
                margin: 10px 0;
            }
        }
    }

    [class$='-group'] {
        color: $charcoal-light;
        background: $white;
        padding: 5px 10px;
        font-size: inherit;
        font-weight: 800;
    }

    [class$='-menu'] {
        margin-top: 0;
        z-index: 2;
    }

    [class$='-MenuList'] {
        padding: 0;
        max-height: 350px;
    }

    [class$='-option'] {
        padding: 4px 10px;

        &:active {
            background-color: $berry;
            color: $white;
        }
    }

    :global {
        .control-focused {
            border: 1px solid $berry;
        }

        .option-selected {
            background: $berry !important;
            color: $white !important;
        }

        .option-focused {
            color: $charcoal-light;
            background: $pale-grey;
        }

        /* stylelint-disable selector-max-compound-selectors */
        .select {
            &__value-container {
                padding: 0;
                line-height: 1.37;
                color: $charcoal-light;
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                height: 100%;
            }

            &__placeholder {
                margin: 0;
                padding-left: 10px !important;
            }

            &__single-value {
                color: $berry;
                font-weight: bold;
                padding-left: 10px !important;
                margin: 0 !important;
            }

            &__input-container {
                display: flex;
                width: 100.5%;
                height: 100%;
                padding: 0;
                margin: 0;
            }

            &__input-container::after {
                max-width: 0;
                display: none;
            }

            &__input {
                min-width: max-content !important;
                height: 100%;
                cursor: default;
                padding-left: 10px !important;
            }
        }
    }
}

$search-input-selector: '.search-input';
$button-width: 55px;

#{$search-input-selector} {
    &__button {
        @include button-base();

        width: $button-width;
        height: 100%;
        transition: all 0.25s ease-in-out;

        &--localize-me {
            background-color: $pale-grey;
            border: 1px solid $rock;
            border-left: 1px solid $pale-grey;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

            &:hover:enabled {
                border: 1px solid $berry;
            }
        }

        &:disabled {
            cursor: default;
            background: lighten($pale-grey, 0.5);

            #{$search-input-selector}__icon {
                opacity: 0.3;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        line-height: 24px;
        color: $stone;

        &::before {
            padding-top: 4px;
        }

        &--clear {
            @include os-icon('close-small');
            color: $rock;
        }

        &--localize-me {
            &::before {
                display: inline-block;
                width: 24px;
                height: 24px;
                content: '';
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url('assets/crosshairs-gps.svg');
            }
        }
    }
}
