@import '~styles/utils';

$transition-time: 300ms;

.confirm-order-button {
    display: flex;

    &__button {
        background-color: $morningmoss;
        border-color: $morningmoss;
        color: $white;
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;

        &:hover {
            background-color: $moss;
        }

        &--disabled,
        &--disabled:hover {
            background-color: $stone;
        }
    }

    &__back {
        button {
            background-color: $pale-grey;
            color: $charcoal-light;
            border-width: 0;
            margin-bottom: 0;

            &:hover {
                background-color: $mist;
                box-shadow: none;
                transition-duration: $transition-time;
            }
        }
    }
}
