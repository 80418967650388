@import '~styles/utils';

.cover-drop-button {
    &__button {
        width: 100%;
        height: 54px;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        justify-content: center;
    }
}
