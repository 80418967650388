@import '~styles/utils';

.tooltip {
    &:hover .tooltip__text {
        visibility: visible;
    }

    &__container {
        position: relative;
    }

    &__text {
        @include absolute($bottom: 125%, $left: 0%, $right: 0%);
        visibility: hidden;
        background-color: $white;
        width: fit-content;
        color: $charcoal;
        margin: 0 auto;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 2px;
        padding: 5px 10px;
        margin-bottom: 15px;

        &::after {
            @include absolute($top: 100%, $left: 50%);
            content: '';
            box-sizing: border-box;
            border: 6px solid $black;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -3px 3px 3px -1px rgba(0, 0, 0, 0.3);
        }
    }

    &__children {
        height: 100%;
        display: flex;
    }
}
