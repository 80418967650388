@import '~styles/utils';

.button {
    position: relative;
    display: flex;
    align-items: center;
    outline-width: 0;

    &--primary {
        @include primary-button();
    }

    &--secondary {
        @include secondary-button();
    }

    &--error {
        @include secondary-button();
        border: 1px solid $error;
        color: $error;
    }

    &--link {
        @include link-button();
    }

    &--loading {
        pointer-events: none;
    }

    &--bottom-bar {
        height: 100%;
        border-radius: 0;
    }

    &--disabled {
        @include disabled-button();
    }

    &__text {
        &--loading {
            visibility: hidden;
        }
    }
}
