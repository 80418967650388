@mixin control-base() {
    height: $input-height;
    padding: 8px 16px;
    border: solid 1px $input-border-color;
    background-color: $white;
    outline: none;
    appearance: none;
    line-height: $global-line-height;
    color: $body-font-color;
    transition-property: border-color, box-shadow;
    transition-duration: 0.2s;

    &::placeholder {
        color: $rock;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }

    &:focus {
        border-color: $berry;
        outline: none;
    }

    &--disabled {
        background-color: $clouds;
    }
}
