@import '~styles/utils';

.htooltip {
    &:hover .htooltip__text {
        visibility: visible;
    }

    &__container {
        position: relative;
    }

    &__text {
        @include absolute($bottom: auto, $left: auto, $right: calc(100% + 16px), $top: -50%);

        visibility: visible;
        width: fit-content;
        margin: 0 auto;
        text-align: right;
        margin-bottom: 15px;
        background-color: $charcoal-light;
        color: $white;
        white-space: nowrap;
        font-size: 14px;

        &::after {
            @include absolute($top: 50%, $left: calc(100% - 9px));
            content: '';
            box-sizing: border-box;
            border: 6px solid $black;
            border-color: transparent $charcoal-light $charcoal-light transparent;
            transform-origin: 0 0;
            transform: rotate(-45deg);
        }

        &__inner {
            padding: 8px 12px;
        }

        &--vertical {
            position: relative;
            right: auto;
            margin: 0 auto;
            text-align: center;

            &::after {
                @include absolute($top: 74%, $left: 50%);
                transform-origin: 0 0;
                transform: rotate(45deg);
            }
        }

        &--timeout-invisible {
            visibility: hidden;
        }

        &--always-visible {
            visibility: visible;
        }

        &__pre {
            font-weight: bold;
        }
    }

    &__container--vertical {
        position: absolute;
        left: 50%;
    }

    &__container__inner--vertical {
        position: relative;
        left: -50%;
    }

    &__children {
        height: 100%;
        display: flex;
    }
}
