@mixin modal-title {
    font-size: 32px;
    color: $stone;

    @include breakpoint(small down) {
        font-size: 18px;
        line-height: 24px;
    }
}

@mixin modal-subtitle {
    @include text-title();
    color: $charcoal;

    @include breakpoint(small down) {
        @include text-small();
    }
}
