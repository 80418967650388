@function char($character-code) {
    @if function-exists('selector-append') {
        @return unquote("'\\#{$character-code}'");
    }

    @if '\\#{' x '}' == '\\x' {
        @return str-slice('\x', 1, 1) + $character-code;
    } @else {
        // prettier-ignore
        @return #{'\'\\'}#{$character-code + '\''};
    }
}

@function get-os-icon-char($icon-name) {
    @if map-has-key($os-icons, $icon-name) == false {
        @warn 'Icon #{$icon-name} not found.';
        @return '';
    }
    @return char(map-get($os-icons, $icon-name));
}

@mixin os-icon($icon-name) {
    &::before {
        display: inline-block;
        font: normal normal normal 24px/1 'OSIcons';
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        content: get-os-icon-char($icon-name);
    }
}
