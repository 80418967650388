@import '~styles/utils';

.map-icon-button {
    border-radius: 50%;
    box-shadow: $shadow-light 0 2px 2px 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 8px 4px;
    background-color: $white;
    transition: all 0.25s ease-in-out;

    &:hover:not(:disabled) {
        background-color: $light-grey;
    }

    &:focus-visible {
        border: 1px solid $black;
    }

    &:disabled {
        background-color: lighten($border-grey, 0.5);
        color: $white;
        box-shadow: none;
    }

    &__icon {
        fill: $charcoal-light;
    }
}

.active {
    background-color: $berry;
    transition: all 0.25s ease-in-out;

    &__icon {
        fill: $white;
    }

    &:hover {
        background-color: $berry-dark !important;
    }
}
