@import '~styles/utils';

.zoom {
    &__container {
        display: flex;
        flex-direction: row;
        background-color: $white;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadow-light 0 2px 2px 0;
        width: 156px;
        height: 40px;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include breakpoint(medium up) {
            flex-direction: column;
            margin-bottom: 10px;
            width: 40px;
            height: 104px;
        }
    }

    &__button {
        all: unset;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: $charcoal-light;
        transition: all 0.25s ease-in-out;

        &:hover {
            color: $foxglove;
            fill: $foxglove;
        }
    }
}
