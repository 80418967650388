@import '~styles/utils';

.form-field {
    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &__label-container {
        display: flex;
        justify-content: space-between;
    }

    &__label {
        @include title();

        line-height: 28px;
        margin-bottom: 4px;
    }

    &__message {
        font-size: 14px;
        line-height: 28px;

        &--info {
            color: $stone;
        }

        &--error {
            color: $error;
        }
    }

    &__controls {
        width: 100%;

        > * {
            width: 100%;
        }
    }

    &--focused {
        @include breakpoint(small down) {
            @include fixed($top: 0, $left: 0);
            width: 100%;
            height: 100%;
            background: $white;
            padding: $focused-form-field-mobile-padding;
            z-index: 2;
        }
    }

    &.form-field--focused {
        @include breakpoint(small down) {
            margin-bottom: 0;
        }
    }
}
