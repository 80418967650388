@import '~styles/utils';

.slider-component {
    width: 100%;
    color: $berry;
    cursor: pointer;
    display: inline-block;
    position: relative;

    [class$='-rail'] {
        color: $rock;
        height: 8px;
        border-radius: 6px;
        background-color: $mist;
        opacity: 1;
    }

    [class$='-track'] {
        color: $berry;
        height: 8px;
        border-radius: 6px;
    }

    [class$='-mark'] {
        width: 0;
        height: 0;
    }

    [class$='-markActive'] {
        width: 0;
        height: 0;
    }

    :global {
        .MuiSlider-thumb {
            background-color: $berry;
            background-color: lighten($berry, 20%);
            width: 16px;
            height: 16px;
            margin-left: -8px;
            margin-top: -4px;
            outline: none;
            transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s, color 0.2s ease 0s;

            &:hover {
                background-color: darken($berry-dark, 10%);
                box-shadow: none;
            }

            &.Mui-focusVisible {
                box-shadow: none;
                background-color: darken($berry-dark, 10%);
            }
        }

        .MuiSlider-thumb[aria-valuenow='0'] {
            margin-left: 0;
        }

        .MuiSlider-thumb[style*='left: 100%'] {
            margin-left: -16px;
        }

        .PrivateValueLabel-circle-4 {
            background-color: $berry-dark;
        }
    }
}

.slider-label {
    display: flex;
    flex-direction: row;
    margin-bottom: -3px;
    justify-content: space-between;
    line-height: 13px;
    margin-top: -3px;
    font-weight: 400;
    color: $black;
    font-size: 14px;
}
