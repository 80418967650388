@import '~styles/utils';

.magnifier {
    display: flex;
    align-items: center;
    justify-content: center;

    &__square {
        position: absolute;
        top: calc(50% - (#{$square-side} / 2));
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        padding-right: 15px;
        height: $square-side;
        width: $square-side;
        border: 1px solid #c13e56;
        background-color: rgba(198, 61, 84, 0.15);
        z-index: 3;

        @include breakpoint(medium) {
            top: calc(58.2% - (#{$small-square-side} / 2));
            height: 42.6px;
            width: $square-side;
        }

        @include breakpoint(large) {
            top: calc(67.8% - (#{$small-square-side} / 2));
            height: 42.6px;
            width: $square-side;
        }

        @include breakpoint(xlarge) {
            top: calc(50% - (#{$square-side} / 2));
            height: $square-side;
            width: $square-side;
        }
    }
}

.product-preview-overlay {
    @include absolute($top: 0, $left: 0);

    width: 100%;
    height: 100%;
    z-index: 2;

    &__zoomView {
        border: 1px solid #d4d4d4;
        width: 302px;

        @include breakpoint(xsmall) {
            height: 180px;
        }

        @include breakpoint(xlarge) {
            height: 302px;
        }
    }

    &__close-button {
        @include absolute($bottom: 0, $left: 0);
        @include button-base();
        @include text-semi-bold();

        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        z-index: 2;
        background-color: $white;
        color: $stone;
        line-height: 18px;

        @include breakpoint(medium) {
            @include absolute($top: 32px, $right: 32px);

            width: auto;
            padding: 8px 20px;
        }
    }

    &__close-icon {
        @include os-icon('close-large');

        font-size: 24px;
        margin-top: 6px;
        margin-left: 8px;

        @include breakpoint(small down) {
            @include absolute($top: 50%, $right: 8px);

            transform: translateY(-50%);
        }
    }

    &__preview {
        position: relative;
        width: 100%;
        height: $map-size-small;
        box-shadow: inset 1px 1px 8px 2px $shadow;

        @include breakpoint(small) {
            height: $map-size-big;
        }
    }

    &__preview-image {
        width: 100%;
        height: 100%;
    }
}
