.map-frame {
    position: relative;
    width: 100%;
    padding-top: 89.2%;
    background-repeat: no-repeat;
    background-size: 100%;

    &--customise {
        margin-top: 0;
        margin-bottom: 0;
    }
}
