@import '~styles/utils';

.language-select {
    margin-top: 16px;

    &__title {
        @include title();
        margin-bottom: 14px;
    }
}
