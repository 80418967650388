@import '~styles/utils';

.cover-titles {
    color: $stone;

    &--folded {
        color: $white;
    }

    &--rectangle {
        position: relative;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    &__spine {
        @include font-sourcesanspro();
        @include absolute($top: 13px, $left: 60px);
        font-weight: bold;
        width: 147px;
        height: 7px;
        font-size: 7px;
        line-height: 7px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;

        @include breakpoint(medium) {
            top: 18px;
            left: 73px;
            width: 192px;
            height: 9px;
            font-size: 9px;
            line-height: 9px;
        }
    }

    &__titles-container {
        @include absolute($top: 34px, $left: 9px);
        white-space: pre;
        width: 184px;
        overflow: hidden;
        text-overflow: clip;

        @include breakpoint(medium) {
            width: 241px;
            top: 45px;
            left: 11px;
        }

        @include breakpoint(xlarge up) {
            left: 22px;
        }

        &--small {
            left: 28px;
            top: 21px;

            @include breakpoint(small down) {
                left: 23px;
                top: 14px;
                width: 237px;
            }
        }

        &--paper-square {
            left: 22px;

            @include breakpoint(large down) {
                left: 11px;
            }

            @include breakpoint(small down) {
                left: 9px;
            }
        }

        &--folded-large {
            left: 22px;

            @include breakpoint(small down) {
                left: 18px;
            }
        }

        &--folded-small {
            left: 28px;
        }

        &--rectangle {
            top: 21px;
            left: 0;
            right: 0;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
        }
    }

    &__title-line {
        @include font-osgill();
        font-weight: normal;
        height: 25px;
        font-size: 17px;
        line-height: 19px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            height: 30px;
            font-size: 22px;
            line-height: 29px;
        }

        @include breakpoint(small down) {
            height: 23px;
        }

        &--small {
            height: 30px;
            font-size: 22px;
            line-height: 23px;
            letter-spacing: 0.2px;
        }

        &--rectangle {
            text-align: center;
            font-size: 14px;
            height: 18px;
            line-height: 18px;

            @include breakpoint(large down) {
                font-size: 12px;
            }

            @include breakpoint(medium down) {
                line-height: 23px;
                height: 23px;
            }

            @include breakpoint(small down) {
                line-height: 13px;
                height: 13px;
            }
        }
    }

    &__subtitle-line {
        @include font-sourcesanspro();
        font-weight: 500;
        height: 14px;
        font-size: 10px;
        line-height: 11px;

        @include breakpoint(medium) {
            height: 16px;
            font-size: 12px;
            line-height: 14px;
        }

        &--small {
            height: 22px;
            line-height: 21px;

            @include breakpoint(small down) {
                height: 14px;
                line-height: 14px;
                font-size: 14px;
            }
        }

        &--rectangle {
            text-align: center;
            font-size: 11px;
            line-height: 16px;
            height: 16px;

            @include breakpoint(large down) {
                font-size: 10px;
                line-height: 8px;
                height: 8px;
            }

            @include breakpoint(medium down) {
                line-height: 10px;
                height: 10px;
            }

            @include breakpoint(small down) {
                line-height: 14px;
                height: 14px;
            }
        }
    }
}
