@import '~styles/utils';

.not-found {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__header {
        margin-bottom: 24px;
    }
}
