@import '~styles/utils';

.home {
    @include background();

    @include breakpoint(large) {
        flex-direction: row;
    }

    &--product {
        background-color: $white;
    }
}
