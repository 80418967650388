@import '~styles/utils';

.non-selected {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 500px;

    &__main-title {
        color: $charcoal-light;
        font-size: 26px;
        font-weight: 400;
        line-height: 32px;

        &__bold {
            font-weight: 600;
        }
    }

    &__options-wrapper {
        padding-top: 20px;
    }

    &__option-container {
        display: flex;
        align-items: center;
        padding: 25px 30px;
        background: $white;
        margin-bottom: 20px;
        cursor: pointer;

        &__image {
            height: 120px;
            max-width: 100%;
            object-fit: cover;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
        }

        &__content {
            padding-left: 25px;

            &__title {
                color: $charcoal-light;
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
            }

            &__description {
                color: $charcoal-light;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
