@import '~styles/utils';

$cover-dropzone-content-selector: '.cover-dropzone-image-content';
$default-icon-color: #746cac;
$transition: all 0.25s ease-in-out;

#{$cover-dropzone-content-selector} {
    @include absolute($top: 0, $left: 0);

    width: 100%;
    height: 100%;
    background-image: url('./assets/defaultDropzoneBackground.svg');
    color: $berry;

    &__background {
        width: 100%;
        height: 100%;
        background-color: rgba(245, 245, 245, 0.9);

        &--cover {
            background: url('./assets/coverImage.png') no-repeat;
            background-size: 100%;
        }
    }

    &--cover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: $charcoal;
        transition: $transition;

        &:hover {
            background-image: url('./assets/defaultDropzoneBackground.svg');
            transition: $transition;
            color: $berry;

            #{$cover-dropzone-content-selector}__hill-icon,
            #{$cover-dropzone-content-selector}__sun-icon {
                color: $default-icon-color;
                transition: $transition;
            }
        }
    }

    &__container {
        position: relative;
        max-width: 220px;
        margin: 40px auto 0;
        text-align: center;

        &--cover {
            max-width: 308px;
            margin: auto;
        }
    }

    &__hill-icon {
        @include os-icon('hills-mountains');

        font-size: 48px;
        display: block;
        line-height: 45px;
        color: $default-icon-color;

        &--cover {
            font-size: 76px;
            color: $icon-color;
            transition: $transition;
        }
    }

    &__sun-icon {
        @include os-icon('sun');

        font-size: 23px;
        display: block;
        padding-left: 25px;
        line-height: 23px;
        margin-bottom: -15px;
        color: $default-icon-color;

        &--cover {
            margin-bottom: -8px;
            padding-left: 56px;
            font-size: 26px;
            color: $icon-color;
            transition: $transition;
        }
    }

    &__title {
        &--cover {
            font-size: 20px;
        }
    }

    &__description {
        font-size: 12px;

        &--cover {
            font-size: 14px;
        }
    }
}
