@import '~styles/utils';

$vertical-bar-width: 80px;
$vertical-bar-color: $rock-light;

.vertical-line {
    width: $vertical-bar-width;
    position: relative;

    &::before {
        position: absolute;
        left: 50%;
        display: block;
        width: 1px;
        height: 100%;
        content: '';
        background-color: $vertical-bar-color;
    }

    &__text {
        height: 100%;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-align: center;
        line-height: $vertical-bar-width;

        span {
            padding: 18px 0;
            background-color: $pale-grey;
            color: $vertical-bar-color;
        }
    }
}
