@import '~styles/utils';

.alert-container {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0;
    min-height: 40px;

    &__message {
        padding-left: 10px;
        padding-right: 10px;
        white-space: pre-line;
        display: flex;
        flex-direction: column;

        &__title {
            font-weight: 600;
        }
    }

    &__restore-button {
        color: $berry;
        font-weight: 600;
        border: 0;
        background-color: transparent;
        padding-top: 5px;
        cursor: pointer;
        font-size: 14px;
        width: 150px;
        display: flex;
        padding-left: 0;
    }

    &__icon {
        width: 30px;
    }
}
