@import '~styles/utils';
@import 'src/components/Modal/index.module';

.map-size-popup {
    max-width: 1250px;
    padding: 0;
    margin: 0;

    &__arrow {
        height: 38px;
        width: 32px;
        cursor: pointer;
    }

    &__header {
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: bold;

        @include breakpoint(small down) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }

    &__dimension-height {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-align: center;
        margin-right: auto;
        font-size: 0.91em;
        margin-left: -5px;

        @include breakpoint(small down) {
            font-size: 0.6em;
            margin-right: auto;
        }

        &__rectangle {
            margin-top: 28px;

            @include breakpoint(large down) {
                margin-top: 35px;
            }

            @include breakpoint(small down) {
                margin-top: 23px;
            }
        }
    }

    &__dimension-width {
        position: absolute;
        bottom: -5px;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.91em;
        left: 0;
        right: 0;
        text-align: center;

        @include breakpoint(small down) {
            font-size: 0.6em;
            left: 10px;
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: flex-end;
        position: relative;
        background-size: cover;
        align-items: center;
        width: 325px;
        height: 325px;

        @include breakpoint(small down) {
            width: 190px;
            height: 190px;
        }

        &__image {
            max-width: 296px;
            position: absolute;
            bottom: 25px;

            @include breakpoint(small down) {
                width: 173px;
                position: absolute;
                bottom: 15px;
            }
        }
    }

    &__description {
        margin-top: 16px;
        text-align: center;

        &__map-name {
            font-size: 20px;
            font-weight: bold;

            @include breakpoint(small down) {
                font-size: 16px;
            }
        }

        &__map-size {
            font-size: 14px;

            @include breakpoint(small down) {
                font-size: 12px;
            }
        }
    }
}
