@import '~styles/utils';

$transition-time: 300ms;

.options-list {
    margin-bottom: 15px;
    display: flex;

    @include breakpoint(medium only) {
        margin-bottom: 0;
    }
}

.type {
    height: 80px;
    display: flex;
    flex: 1;
    cursor: pointer;
    transition-duration: $transition-time;
    align-items: center;
    text-align: center;

    &__description {
        flex: 1 0 auto;
        @include breakpoint(xsmall) {
            width: auto;
        }
        @include breakpoint(medium) {
            width: auto;
        }
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    @media screen and (max-width: 340px) {
        &__description-first-line {
            @include text-small();
        }
    }
}
