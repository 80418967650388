@import '~styles/utils';

.upload-button {
    width: 100%;
    height: 54px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    justify-content: center;
}
