@import '~styles/utils';

.container {
    display: flex;
    padding: 15px 2px;
    transition: border-color $transition-time;
    align-items: center;
}

.thickness-wrapper {
    padding: 5px;
    font-weight: 400;
    color: $black;
    -webkit-font-smoothing: antialiased;
}

.thickness-button {
    display: block;
    width: 38px;
    height: 5px;
    flex-shrink: 0;
    background-color: $black;
    border: 2px solid $white;
    transition: opacity 0.2s ease 0s;
    cursor: pointer;
    padding: 0;
    transition: all $transition-time;

    &__selected {
        border-radius: 2px;
        outline: none;
        box-shadow: $berry 0 0 0 2px !important;
        border: 2px solid $white !important;
    }

    &--very-thin {
        height: 6px;
    }

    &--thin {
        height: 7px;
    }

    &--regular {
        height: 9px;
    }

    &--thick {
        height: 11px;
    }

    &:hover {
        opacity: 0.7;
    }
}
