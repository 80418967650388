@import '~styles/utils';

.question-icon {
    @include os-icon('help-notification');
    margin-left: 20px;
    font-size: 24px;
    color: $berry;
    cursor: pointer;
    @include breakpoint(large) {
        margin-left: 0;
    }
}
