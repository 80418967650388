@import '~styles/utils';

$transition-time: 300ms;

.crop-modal {
    max-width: 640px;

    &__title {
        @include modal-title();
    }

    &__button {
        height: 54px;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;

        &--cancel {
            border-width: 0;
            background-color: $pale-grey;

            &:hover {
                background-color: $mist;
                transition-duration: $transition-time;
            }
        }
    }

    &__description {
        @include modal-subtitle();
    }

    &__crop-zone {
        max-width: 640px;
        margin: 15px auto 20px;
        text-align: center;

        @include breakpoint(medium down) {
            margin: 15px auto 10px;
        }
    }
}

/* stylelint-disable-next-line selector-class-pattern */ /*comes from library*/
:global(.ReactCrop__crop-selection) {
    box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.4);
    border: 1px dashed $white;
}

/* stylelint-disable-next-line selector-class-pattern */ /*comes from library*/
:global(.ReactCrop__image) {
    max-height: calc(90vh - 327px);
}
