@import '~styles/utils';

.map-titles-preview-overlay {
    @include absolute($top: -50px, $right: -50px);

    width: 320px;
    height: 320px;
    z-index: 1;
    border: 1px solid $stone;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.3);
    background-position: calc(100% - 80px) 60px;
    background-repeat: no-repeat;
    background-size: 490% 490%;

    @include breakpoint(medium) {
        width: 410px;
        height: 410px;
        top: -70px;
        right: -80px;
        background-size: 550% 550%;
        background-position: calc(100% - 80px) 80px;
    }

    @include breakpoint(xlarge) {
        width: 440px;
        height: 440px;
        background-position: calc(100% - 80px) 90px;
    }

    &--rectangle {
        bottom: 159px;
        top: auto;
        left: 50%;
        right: auto;
        box-shadow: none;
        transform: translate(-50%, 0);
        border-width: 0;
        background-color: transparent;
        height: 80px;
        width: fit-content;

        @include breakpoint(xlarge down) {
            bottom: 159px;
        }

        @include breakpoint(large down) {
            bottom: 100px;
        }

        @include breakpoint(medium down) {
            bottom: 138px;
        }

        @include breakpoint(small down) {
            bottom: 69px;
        }
    }

    &__titles-container {
        @include absolute($top: 90px, $left: 50px);

        @include breakpoint(medium) {
            top: 130px;
            left: 60px;
        }

        &--rectangle {
            width: 100%;
            height: 100%;
            position: relative;
            left: 0;
            top: 0;
        }
    }
}
