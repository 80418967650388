@import '~styles/utils';

.product-preview {
    width: 320px;
    margin-top: 20px;
    margin-bottom: 70px;
    margin-bottom: 116px;
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-size: cover;
    background-repeat: round;

    @include breakpoint(medium) {
        width: 674px;
        margin: 0;
    }

    @include breakpoint(xlarge) {
        width: 780px;
    }

    @include breakpoint(small down) {
        &--small {
            width: 340px;
        }
    }

    &--mobile-magnifier-open {
        margin-bottom: 166px;
    }

    &__tooltip {
        position: absolute;
        left: 50%;
        top: 8%;
        z-index: 9999;

        @include breakpoint(small down) {
            top: 4%;
        }

        @include breakpoint(large up) {
            top: 8%;
        }

        @include breakpoint(xlarge up) {
            top: 12%;
        }
    }

    &__tooltip--rectangle {
        top: -15%;

        @include breakpoint(medium up) {
            top: -2%;
        }

        @include breakpoint(xlarge up) {
            top: 5%;
        }
    }

    &__tooltip-uk-overview {
        position: absolute;
        left: calc(50% - 3px);
        top: calc(50% - 69px);
    }

    &__spacer {
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 20px;
        padding: 13px 48px 48px 13px;

        @include breakpoint(medium) {
            height: calc(100% - 80px);
            margin: 40px;
            padding: 24px 89px 25px 24px;
        }

        @include breakpoint(xlarge) {
            padding: 28px 110px 30px 28px;
        }

        &--rectangle {
            height: calc(100% - 10px);
            padding: 18px 18px 57px;

            @include breakpoint(medium) {
                height: calc(100% - 54px);
                padding: 29px 29px 85px;
            }

            @include breakpoint(xlarge) {
                padding: 34px 34px 102px;
            }
        }

        &--no-frame {
            padding: 0;
            margin: 0;
            height: 100%;
        }
    }

    &__map {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: move;

        &--no-move {
            cursor: default;
        }
    }
}
