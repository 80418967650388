@import '~styles/utils';

.options-tab {
    display: block;

    &__tooltip {
        color: $orange;
        font-size: 12px;
        margin-bottom: 5px;

        &__size-icon {
            float: right;
            margin-top: -2px;
            color: $berry;
            font-size: 14px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__header {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__delivery {
        font-style: italic;
        margin-bottom: 5px;
    }
}
