$berry: #453c90;
$berry-dark: #292074;
$foxglove: #d40058;
$morningmoss: #018044;

$black: #000;
$charcoal: #333;
$charcoal-light: #3c3c3c;
$stone: #666;
$rock: #999;
$rock-light: #aaa;
$pale-grey: #f3f3f4;
$icon-color: #bcbcbc;
$light-grey: #e7e7e7;
$mist: #ddd;
$border-grey: #d6d6d7;
$clouds: #f5f5f5;
$white: #fff;
$orange: #e55000;
$light-orange: #ff5f00;
$pink: #d30258;

$sand: #9f8f7a;
$moss: #006428;
$slate: #4d5169;
$heather: #77545d;

$error: #c63d54;
$validation-error: #e02020;
$warning: #ffb000;
$success: #118011;
$link-or-info: #0071bc;
$link-hover: #2a99dd;
$link-visited: #4c2c92;

$input-border-color: #999;
$shadow: rgba(0, 0, 0, 0.5);
$shadow-light: rgba(0, 0, 0, 0.1);
