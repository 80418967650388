@import '~styles/utils';

.product-preview {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;

    @include breakpoint(large) {
        height: 100%;
        overflow-y: auto;
        flex-grow: 1;
        order: 2;
    }

    &--hidden {
        display: none;
    }
}

@include breakpoint(medium down) {
    .product-preview--journey-step-createproductjourneystepcustomise {
        height: 520px;

        @include breakpoint(medium) {
            height: 720px;
        }

        &.product-preview--product-flat-map {
            height: 360px;

            @include breakpoint(medium) {
                height: 440px;
            }
        }
    }
}
