@import '~styles/utils';

.journey-button {
    width: 100%;
    text-align: right;
    display: flex;

    &__button {
        width: 100%;
        height: 54px;
        text-align: center;
        align-content: center;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }
}
