@import '~styles/utils';

.order-confirmation {
    @include background();
    padding: 40px 20px;
    overflow: auto;

    &__container {
        @include container();
        background-color: $white;
    }

    &__button {
        @include os-icon('left-arrow-large');
        color: $link-or-info;

        &::before {
            margin: 0 5px -5px 0;
            font-size: 20px;
        }
    }
}
