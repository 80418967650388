@import '~styles/utils';

.cover-dropzone {
    position: relative;
    height: 190px;
    margin-top: 9px;
    overflow: hidden;
    background-color: $white;
    border: 1px solid $berry;
    cursor: pointer;
    transition-duration: 0.4s;

    &--cover {
        display: flex;
        align-items: center;
        height: 100%;
        margin-top: 0;
        border: 0;
        border-radius: 0 0 2px 2px;
    }

    &--large {
        width: 600px;
        height: 550px;
        position: relative;

        @include breakpoint(xsmall) {
            height: 320px;
        }

        @include breakpoint(small) {
            height: 400px;
        }

        @media (min-width: 500px) {
            height: 550px;
        }
    }

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &--uploaded:hover {
        box-shadow: 0 0 8px 3px $shadow;
        transition-duration: 0.4s;
    }
}
