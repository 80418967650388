@import '~styles/utils';

.titles-form {
    margin-bottom: 16px;

    @include breakpoint(small) {
        width: 100%;
    }

    @include breakpoint(large) {
        width: auto;
    }

    &__header {
        margin-top: 32px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:nth-of-type(1) {
            font-size: 16px;
            font-weight: 600;
            color: $charcoal-light;
        }

        div:nth-of-type(2) {
            font-size: 14px;
            color: $rock;
        }

        &:not(:first-of-type) {
            margin-top: 16px;
        }

        &--nomargin {
            margin-bottom: 0;
        }
    }

    input {
        margin-bottom: 8px;
    }
}
