@import '~styles/utils';

.cover-dropzone-route-content {
    width: 100%;
    height: 100%;
    color: $charcoal;
    background-position: center;
    transition: all 0.25s ease-in-out;
    stroke: $icon-color;

    @media (min-width: 550px) {
        background-image: url('./assets/default-dropzone-background.svg');
    }

    &__background {
        width: 100%;
        height: 100%;
        background: url('./assets/cover-image.jpg') no-repeat;
        background-size: 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 110px;
        padding-left: 40px;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &__container {
        position: relative;
        max-width: 350px;
        margin: 0 auto;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    &__title {
        padding-top: 15px;
        font-size: 20px;
        font-weight: 400;

        @include breakpoint(xsmall down) {
            font-size: 13px;
        }
    }

    &__description {
        font-size: 12px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        padding-top: 10px;
        max-width: 235px;

        @include breakpoint(xsmall down) {
            font-size: 10px;
        }
    }

    &:hover {
        transition: all 0.25s ease-in-out;
        color: $berry;
        stroke: lighten($berry, 25%);
    }
}
