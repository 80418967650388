@import '~styles/utils';

.preview-map {
    width: 100%;
    height: 100%;

    &__print-area {
        position: relative;
        width: 100%;
        height: $map-size-small;
        box-shadow: inset 1px 1px 8px 2px $shadow;

        @include breakpoint(medium) {
            height: $map-size-big;
        }
    }

    &__center-point-icon {
        @include absolute($top: 50%, $left: 50%);

        width: 48px;
        height: 48px;
        transform: translate(-50%, -50%);
        background-image: url('./assets/crosshairs-thin.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        pointer-events: none;
    }
}
