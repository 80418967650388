@mixin bottom-margin() {
    margin-bottom: 16px;
}

@mixin background() {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $pale-grey;
}

@mixin container() {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;

    @include breakpoint(xsmall) {
        padding: 5px;
    }

    @include breakpoint(small) {
        padding: 40px;
    }
}
