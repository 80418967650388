@import '~styles/utils';

.map-selection-container {
    margin: auto;
    height: 420px;
    display: flex;
    color: $white;
    position: relative;

    @include breakpoint(medium) {
        height: 610px;
    }

    @include breakpoint(xlarge) {
        height: 700px;
    }

    @include breakpoint(medium only) {
        margin: 30px 0;
    }

    &--rectangle {
        height: 370px;

        @include breakpoint(medium) {
            height: 500px;
        }

        @include breakpoint(xlarge) {
            height: 580px;
        }
    }

    &--mobile-magnifier-open {
        height: 470px;
    }

    &--rectangle-mobile-magnifier-open {
        height: 420px;
    }

    &__vertical-line {
        display: none;
        margin: auto;
        height: 78%;

        @include breakpoint(medium) {
            display: block;
        }

        &--map-size-small-map-type-paper {
            height: 100%;
        }

        &--map-size-small-map-type-canvas_and_framed,
        &--map-size-medium-map-type-canvas_and_framed {
            margin-top: 8%;
            height: 66%;
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__actions {
        width: 70px;
        margin-left: -73px;
        z-index: 2;
        padding-top: 4px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 16px;
        pointer-events: none;

        @include breakpoint(small down) {
            padding-right: 25px;
        }

        @include breakpoint(medium) {
            margin-left: 0;
            flex-direction: column;
            justify-content: normal;
            align-items: center;
        }

        &__centerings {
            pointer-events: auto;
        }

        &__centerings &:nth-child(1) button {
            @include breakpoint(small down) {
                z-index: 2;
            }
        }

        button {
            transform: scale(1.2);
            border-width: 0;
            padding: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            border-color: transparent !important;
        }
    }
}
