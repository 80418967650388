@import '~styles/utils';

.sidebar-tabs {
    height: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    &__tab-list {
        display: flex;
        flex-direction: row;
        height: 66px;
        background-color: $pale-grey;

        @include breakpoint(medium down) {
            border-top-style: solid;
            border-top-color: $mist;
            border-top-width: 1px;
        }
    }

    &__tab {
        @include text-small();
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: (100% / 3);
        padding: 12px 0 9px;
        text-align: center;
        cursor: pointer;
        color: $charcoal-light;

        @include breakpoint(medium down) {
            margin-top: 1px;
        }

        &--selected {
            background-color: $white;
            color: $berry;

            .sidebar-tabs__tab-icon {
                filter: invert(21%) sepia(32%) saturate(2955%) hue-rotate(226deg) brightness(72%) contrast(88%);
            }
        }
    }

    &__tab-icon {
        font-size: 24px;
        line-height: 23px;

        &::before {
            display: inline-block;
            width: 24px;
            height: 24px;
            content: '';
            background-repeat: no-repeat;
            background-position: center center;
        }

        &--location::before {
            background-image: url('assets/pin.svg');
        }

        &--options::before {
            background-image: url('assets/map.svg');
        }

        &--customise::before {
            background-image: url('assets/brush.svg');
        }
    }
}
