@import '~styles/utils';

.options-list {
    margin-bottom: 15px;

    @include breakpoint(medium only) {
        margin-bottom: 0;
    }
}

.option {
    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &__icon {
        margin: 5px 0 0 5px;
        transition-duration: $transition-time;
        max-width: 70px;
        min-width: 70px;
        min-height: 70px;
        max-height: 70px;
    }

    &__description {
        padding-bottom: 6px;
        flex: 1 0 auto;
        margin: 15px 0 0 19px;

        @include breakpoint(xsmall) {
            width: 182px;
        }
        @include breakpoint(medium) {
            width: auto;
        }
    }

    &__description-second-line {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-right: 15px;
    }

    &__price-integer {
        @include price-integer();
        font-size: 16px;

        &__currency {
            margin-right: 4px;
        }
    }

    &__price-decimal {
        @include price-decimal();
        font-size: 16px;
    }

    &__dimension {
        @include text-small();

        &__asteriks {
            color: $orange;
        }
    }

    @media screen and (max-width: 340px) {
        &__description-first-line {
            @include text-small();
        }

        &__dimension {
            @include text-very-small();
        }

        &__price-integer {
            @include text-semi-bold();
            font-size: inherit;
        }

        &__price-decimal {
            @include text-very-small();
        }
    }
}
