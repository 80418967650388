@import '~styles/utils';

.form-error-message {
    display: flex;
    justify-content: flex-end;

    &__inner {
        line-height: 28px;
        color: $error;
        font-weight: 600;
    }
}
