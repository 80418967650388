.tour-guide {
    &__header {
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__content {
        margin-bottom: 10px;
    }

    &__close {
        div + button {
            width: 30px;
            height: 30px;
            padding: 10px;
        }
    }
}
