@import '~styles/utils';

.style-section {
    padding-top: 20px;

    &__title {
        font-size: 16px;
        font-weight: 600;
        color: $charcoal-light;
    }
}
