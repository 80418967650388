@import '~styles/utils';

.folded-cover-preview {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include breakpoint(large) {
        align-items: center;
    }
  
    &__container {
        position: relative;
        margin-top: 60px;

        @include breakpoint(medium) {
            margin-top: 100px;
        }

        @include breakpoint(large) {
            margin-right: 200px;
        }

        &--small {
            @include breakpoint(small down) {
                margin-top: 17px;
            }
        }
    }

    &__behind-cover {
        @include absolute($top: -87px, $left: 43px);

        width: 385px;

        @include breakpoint(medium) {
            @include absolute($top: -114px, $left: 56px);

            width: auto;
        }

        &--small {
            @include breakpoint(small down) {
                left: 123px;
            }
        }
    }

    &__front-cover {
        width: 260px;
        height: 439px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;

        @include breakpoint(medium) {
            width: 340px;
            height: 576px;
        }

        &--small {
            height: 479px;

            @include breakpoint(small down) {
                width: 340px;
                height: 479px;
            }
        }
    }

    &__bottom-background {
        flex-grow: 1;
        height: 0;
        border-radius: 2px;
    }

    &__cover-image {
        @include absolute($top: 50%, $left: 50%);
        transform: translate(-50%, -50%);
        width: 100%;

        &--cover-small {
            width: 100%;
        }

        &--cover-large {
            height: 100%;
            width: 100%;
        }
    }
}
