@import '~styles/utils';

$zoom-size: 350px;
$zoom-size-mobile-scaledown: 2;

$zoom-size-half: $zoom-size / 2;

.detailed-view-map {
    width: 100%;
    height: 100%;

    &__zoom {
        position: absolute;
        z-index: 3;
        width: $zoom-size;
        height: $zoom-size;
        border-radius: 50%;
        border-width: 2px;
        border-color: $white;
        border-style: solid;
        box-shadow: rgb(100, 100, 100) 0 0 16px 0;
        overflow: hidden;
        left: calc(50% - #{$zoom-size-half});
        top: calc(50% - #{$zoom-size-half});
        background-color: rgba(255, 255, 255, 0.2);
        cursor: default;

        @include breakpoint(small down) {
            height: #{$zoom-size / $zoom-size-mobile-scaledown};
            width: #{$zoom-size / $zoom-size-mobile-scaledown};
            left: calc(50% - #{$zoom-size-half / $zoom-size-mobile-scaledown});
            top: calc(50% - #{$zoom-size-half / $zoom-size-mobile-scaledown});
        }

        &__map {
            position: absolute;
            width: 100%;
            height: 100%;

            &--dragged {
                opacity: 0;
            }
        }

        &__crosshair {
            position: absolute;
            left: calc(50% - 20px);
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            background-image: url('../../assets/crosshair.svg');
            background-size: contain;
        }

        &__cursor {
            position: absolute;
            width: 100%;
            height: 100%;

            circle {
                fill: transparent;
            }

            circle:hover {
                cursor: grab;
            }

            circle:active,
            &--dragged circle {
                cursor: grabbing;
            }
        }
    }
}
