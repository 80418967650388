@import '~styles/utils';

.lock-map-section {
    &__button-forth {
        margin-top: 24px;
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 54px;
    }

    &__button-back {
        button {
            background-color: $pale-grey;
            color: $charcoal-light;
            border-width: 0;
            margin-bottom: 0;

            &:hover {
                background-color: $mist;
                box-shadow: none;
                transition-duration: $transition-time;
            }
        }
    }
}
