@import '~styles/utils';

$transition-time: 300ms;

.frame-type-list {
    display: flex;
    padding-bottom: 15px;
}

.frame-type {
    width: 100%;
    display: block;
    flex: 1;

    &__description {
        transition-duration: $transition-time;
        padding: 15px;

        @include breakpoint(xsmall) {
            width: auto;
        }
        @include breakpoint(medium) {
            width: auto;
        }

        &-first-line {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    @media screen and (max-width: 340px) {
        &__description-first-line {
            @include text-small();
        }
    }
}
