@import '~styles/utils';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 2px;
    transition: border-color $transition-time;
    min-width: 220px;
}

.color-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    @include breakpoint(small down) {
        width: 38px;
        height: 38px;
    }
}

.color-button {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all $transition-time;
    cursor: pointer;
    border: 2px solid $white;
    flex-shrink: 0;

    @include breakpoint(small down) {
        width: 34px;
        height: 34px;
    }

    &--black {
        background-color: $black !important;

        &.active {
            box-shadow: $black 0 0 0 2px !important;
        }
    }

    &--purple {
        background-color: $berry !important;

        &.active {
            box-shadow: $berry 0 0 0 2px !important;
        }
    }

    &--red {
        background-color: $pink !important;

        &.active {
            box-shadow: $pink 0 0 0 2px !important;
        }
    }

    &--green {
        background-color: #33691f !important;

        &.active {
            box-shadow: #33691f 0 0 0 2px !important;
        }
    }

    &--yellow {
        background-color: #ffc00a !important;

        &.active {
            box-shadow: #ffc00a 0 0 0 2px !important;
        }
    }

    &--blue {
        background-color: #0498ce !important;

        &.active {
            box-shadow: #0498ce 0 0 0 2px !important;
        }
    }

    &:hover {
        opacity: 0.7;
    }
}
