@import '~styles/utils';

.summary-price {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    color: $charcoal;
    height: 30px;

    &--big {
        font-size: 24px;
    }

    &__price {
        @include font-osgill();
        display: flex;
        align-items: baseline;

        &--integer {
            @include price-integer();
            font-size: 20px;
        }

        &--integer-big {
            font-size: 32px;
        }

        &--decimal {
            @include price-decimal();
            @include text-semi-bold();
        }

        &--decimal-big {
            font-size: 20px;
        }
    }
}
