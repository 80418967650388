@import '~styles/utils';

.product-summary {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    width: 205px;
    color: $charcoal-light;
    flex-wrap: wrap;

    &--price {
        @include price-integer();
        @include text-semi-bold();
        margin-left: 3px;
        white-space: nowrap;
        font-size: 24px;
    }

    &--scale {
        @include text-small();
        @include text-semi-bold();
        margin-left: 7px;
        font-size: 16px;

        @include breakpoint(small) {
            margin-left: 4px;
            margin-right: 2px;
            width: auto;
        }
    }
}
