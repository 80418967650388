@import '~styles/utils';

.modal {
    @include relative;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-top: 8px solid $berry;
    width: 100%;
    height: 100%;

    @include breakpoint(medium) {
        min-width: 720px;
        max-width: 720px;
        height: auto;
        max-height: 100%;
    }

    &--danger {
        border-top-color: $error;
    }

    &:focus {
        outline: none;
    }

    &__overlay {
        @include absolute(0, 0, 0, 0);
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);

        @include breakpoint(small down) {
            align-items: flex-end;
        }
    }

    &__content {
        margin-top: 20px;
        padding: 0 40px 20px;
        overflow-x: inherit;
        flex: 1 0 auto;
        max-height: calc(100% - 147px);
    }

    &__close-button {
        @include os-icon('close-large');
        position: absolute;
        right: 0;
        padding: 12px 20px;
        font-size: 24px;
        cursor: pointer;
    }

    &__hide {
        visibility: hidden;
    }

    &__buttons {
        margin-top: auto;
        padding: 20px 24px;
        display: flex;
        justify-content: flex-end;
        background-color: $clouds;

        button:not(:last-child) {
            margin-right: 20px;
        }
    }
}
