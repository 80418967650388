@import '~styles/utils';

.cover-select {
    @include breakpoint(large) {
        width: 100%;
    }

    &__container {
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__header {
        margin-top: 24px;
        margin-bottom: 16px;
        color: $charcoal-light;
    }

    &__header-top {
        color: $charcoal-light;
        font-weight: 600;
        vertical-align: middle;
        line-height: 28px;

        &--invalid {
            color: $error;
        }
    }
}
