@import '~styles/utils';

.sidebar-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 16px;

    &__tab-panel-wrapper {
        flex: 1;
        overflow-y: auto;
        height: 100%;

        &--customize-tab {
            height: 100%;
            flex: 1;
            overflow: hidden;
        }
    }

    &__tab-panel {
        &--selected {
            height: 100%;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            overflow-x: hidden;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            position: relative;

            @include breakpoint(large) {
                overflow-y: auto;
            }
        }
    }

    &__tab-panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        flex: 1 0 auto;
        padding: 16px 16px 0;

        @include breakpoint(medium only) {
            max-width: 600px;
            margin: auto;
        }

        @include breakpoint(medium) {
            padding: 20px 20px 0;
        }

        @include breakpoint(large) {
            padding: 20px 20px 0;
        }
    }
}
