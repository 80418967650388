@import '~styles/utils';

$map-preview-selector: '.map-preview';
$map-preview-width-medium: 640px;

#{$map-preview-selector} {
    width: 300px;
    margin: 32px auto;

    @include breakpoint(medium) {
        margin: 32px auto;
        width: $map-preview-width-medium;
    }

    @include breakpoint(large) {
        margin: auto;
    }

    @include breakpoint(xlarge) {
        width: 800px;
    }

    &__vertical-line {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &--customise {
        margin: 0 auto;

        @include breakpoint(medium) {
            margin: 32px auto;
        }

        @include breakpoint(large) {
            margin: auto;
        }

        @include breakpoint(small down) {
            @include absolute($right: 0);

            width: $map-preview-width-medium;

            #{$map-preview-selector}__scene {
                height: 580px;
            }

            #{$map-preview-selector}__inner {
                width: 600px;
            }

            #{$map-preview-selector}__left-side {
                height: 608px;
                transform: translateX(-40px) translateY(-14px) rotateY(-30deg);
            }

            #{$map-preview-selector}__left-side--frame {
                height: 588px;
                transform: translateX(-38px) translateY(-4px) rotateY(-30deg);
            }
        }

        &--rectangle {
            @include breakpoint(small down) {
                width: 742px;
                right: auto;
                left: 50%;
                transform: translate(-50%, 0);
            }

            @include breakpoint(medium) {
                width: 742px;
            }

            @include breakpoint(xlarge) {
                width: 1033px;
            }
        }
    }

    &__scene {
        width: 100%;
        height: 270px;
        perspective: 800px;

        @include breakpoint(medium) {
            position: static;
            height: 580px;
        }

        @include breakpoint(xlarge) {
            height: 720px;
        }

        &--rectangle {
            margin-top: -255px;

            @include breakpoint(medium down) {
                height: 652px;
            }

            @include breakpoint(large) {
                margin-top: 0;
            }

            @include breakpoint(xlarge) {
                height: 823px;
            }
        }

        &--canvas {
            transform: translateX(32px);

            @include breakpoint(medium) {
                transform: translateX(95px);
            }

            @include breakpoint(xlarge) {
                transform: translateX(130px);
            }
        }
    }

    &__front {
        position: relative;
        height: 100%;
        background-repeat: no-repeat;
        overflow: hidden;

        &--folded {
            background-image: url('./assets/mapFolded.png');
            background-size: 100%;
        }

        &--flat {
            background-color: $white;
        }

        &--flat-framed {
            background-image: url('./assets/mapFrame.png');
            background-size: contain;

            &--black {
                background-image: url('./assets/mapFrameBlack.png');
            }

            &--small {
                background-image: url('./assets/mapFrameSmall.png');
            }

            &--small--black {
                background-image: url('./assets/mapFrameSmallBlack.png');
            }

            &--medium {
                background-image: url('./assets/mapFrameMedium.png');
            }

            &--medium--black {
                background-image: url('./assets/mapFrameMediumBlack.png');
            }
        }

        &--canvas {
            position: absolute;
            background-color: $white;
            transform: rotateY(20deg) translateZ(-75px);
            width: 100%;
        }

        &--canvas-framed {
            background-image: url('./assets/mapFrame.png');
            background-size: contain;
            transform: rotateY(20deg) translateZ(-75px);
            width: 100%;

            &--black {
                background-image: url('./assets/mapFrameBlack.png');
            }
        }
    }

    &__inner {
        @include absolute($top: 50%, $left: 50%);

        width: 276px;
        transform: translate(-50%, -50%);

        @include breakpoint(medium) {
            width: 600px;
        }

        @include breakpoint(xlarge) {
            width: 720px;
        }

        &--rectangle {
            transform: translate(-50%, -40%);

            @include breakpoint(large down) {
                width: 500px;
            }
        }
    }

    &__preview-image {
        @include absolute($top: 13px, $left: 13px);

        width: 220px;

        @include breakpoint-map(
            (
                medium: (
                    width: 484px,
                    top: 25px,
                    left: 25px,
                ),
                xlarge: (
                    width: 580px,
                    top: 30px,
                    left: 30px,
                ),
            )
        );

        &--customise {
            width: 484px;
            top: 25px;
            left: 25px;

            @include breakpoint(xlarge) {
                width: 580px;
                top: 30px;
                left: 29px;
            }
        }

        &--customise--rectangle {
            width: 454px;
            top: 24px;
            left: 24px;

            @include breakpoint(xlarge) {
                width: 653px;
                top: 33px;
                left: 33px;
            }

            @include breakpoint(small down) {
                width: 545px;
                top: 28px;
                left: 29px;
            }
        }
    }

    &__product-size {
        position: absolute;
        margin-top: 18px;
        margin-left: -60px;
        height: 537px;

        @include breakpoint(xlarge) {
            height: 678px;
        }

        &--canvas-framed,
        &--canvas {
            margin-top: 0;
            margin-left: -7px;
            height: 592px;

            @include breakpoint(xlarge) {
                height: 740px;
            }
        }

        div {
            height: 100%;
        }
    }

    &__left-side {
        @include absolute($top: 0);

        transform: translateX(-40px) translateY(4px) rotateY(-30deg);
        width: 35px;
        height: 264px;
        background-color: #eaeaea;
        background-size: cover;

        @include breakpoint(medium) {
            height: 608px;
            transform: translateX(-40px) translateY(-14px) rotateY(-30deg);
        }

        @include breakpoint(xlarge) {
            height: 780px;
            transform: translateX(-65px) translateY(-32px) rotateY(-30deg);
        }

        &--frame {
            transform: translateX(-35px) translateY(8px) rotateY(-30deg);
            width: 30px;
            height: 256px;
            background-image: url('assets/mapLeftWood.png');
            background-color: transparent;
            background-size: cover;

            @include breakpoint(medium) {
                height: 588px;
                transform: translateX(-38px) translateY(-4px) rotateY(-30deg);
            }

            @include breakpoint(xlarge) {
                height: 755px;
                transform: translateX(-50px) translateY(-18px) rotateY(-30deg);
            }

            &--black {
                background-image: url('assets/mapLeftWoodBlack.png');
            }
        }
    }
}
