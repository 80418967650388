@import '~styles/utils';

.circle-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__button {
        position: relative;
        width: 34px;
        height: 34px;
        padding: 2px;
        outline: 0;
        box-sizing: content-box;
        border-style: solid;
        border-width: 2px;
        border-radius: 50%;
        border-color: transparent;
        cursor: pointer;

        &:active,
        &:focus {
            border-color: $berry;
        }
    }

    &__text {
        margin-top: 6px;
        text-align: center;
        color: $berry;
        font-size: 12px;
        font-weight: 600;
    }
}
