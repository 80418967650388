@import './colors';

$global-font-size: 100%;
$global-line-height: 1.37;
$body-background: $white;
$body-font-family: 'Source Sans Pro';
$body-font-color: $charcoal-light;

$breakpoints: (
    xsmall: 0,
    small: 413px,
    medium: 840px,
    large: 1160px,
    xlarge: 1440px,
    xxlarge: 1920px,
);

$os-icons: (
    'expand': E91a,
    'place-pin': E96b,
    'style': E94e,
    'shapes': E957,
    'personalise': E958,
    'info-notification': E927,
    'help-notification': E928,
    'close-small': E921,
    'close-large': E922,
    'sun': E952,
    'hills-mountains': E997,
    'crosshairs-thin': E946,
    'right-arrow-large': E91b,
    'left-arrow-large': E91c,
    'down-arrow-large': E916,
    'rotate': E994,
    'error': E925,
    'success': E926,
    'info': E927,
    'move': E929,
    'basket': E92f,
    'map': E944,
    'tick': E923,
);
// Controls
$input-height: 54px;
$focused-form-field-mobile-padding: 10px;
// Map
$map-size-small: 178px;
$map-size-big: 356px;
// Preview
$square-side: 71.2px;
$small-square-side: 35.6px;
