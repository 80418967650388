@import '~styles/utils';

.quantity-chooser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $charcoal;

    &__title {
        font-size: 18px;
        margin-right: 45px;

        @include breakpoint(small down) {
            margin-right: 14px;
        }
    }

    &__input {
        margin: 0 25px;
        padding: 5px;
        width: 90px;
        text-align: center;
    }
}
