@import '~styles/utils';

.toggle {
    &__button {
        $toggle-button-selector: '.toggle__button';
        @include text-semi-bold();
        @include button-base();
        outline-width: 0;
        border: 1px solid $mist;
        background-color: $white;
        color: $stone;
        height: 48px;
        width: 50%;

        #{$toggle-button-selector}--left#{$toggle-button-selector}--inactive {
            border-right-color: $input-border-color;
        }

        &:hover {
            @include breakpoint(large up) {
                border-color: $berry;
                background-color: rgba($berry, 0.05);
                color: $charcoal;
            }
        }

        &--active {
            border-color: $berry;
            background-color: rgba($berry, 0.05);
            color: $charcoal;
            cursor: default;
            pointer-events: none;
        }

        &--disabled {
            color: $pale-grey !important;
            border: 1px solid $pale-grey !important;
            background-color: $white !important;
            cursor: default !important;
        }
    }
}
