@import '~styles/utils';

.scale-color {
    width: 70px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium up) {
        width: 24px;
        border-radius: 4px;
        position: relative;
    }

    &--explorer {
        background-color: $light-orange;
    }

    &--explorer-lock {
        fill: $white;
        background-color: $light-orange;

        @include breakpoint(medium up) {
            fill: $light-orange;
            background-color: $white;
        }
    }

    &--landranger {
        background-color: $foxglove;
    }

    &--landranger-lock {
        fill: $white;
        background-color: $foxglove;

        @include breakpoint(medium up) {
            fill: $foxglove;
            background-color: $white;
        }
    }

    &__value {
        height: 100%;

        @include breakpoint(medium up) {
            position: absolute;
            top: 0;
            left: 30px;
            display: flex;
            width: 55px;
            height: 22px;
        }

        &__arrow {
            @include breakpoint(medium up) {
                width: 0;
                height: 0;
                border-top: 11px solid transparent;
                border-bottom: 11px solid transparent;
                border-right: 11px solid inherit;
            }

            &--explorer {
                border-right: 11px solid $light-orange;
            }

            &--landranger {
                border-right: 11px solid $foxglove;
            }
        }

        &__text {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: $white;

            @include breakpoint(medium up) {
                width: 100%;

                &--explorer {
                    background-color: $light-orange;
                }

                &--landranger {
                    background-color: $foxglove;
                }
            }
        }
    }

    &__icon {
        @include breakpoint(small down) {
            transform: scale(0.68);
        }
    }
}
