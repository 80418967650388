@mixin position($position, $top: auto, $right: auto, $bottom: auto, $left: auto) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin fixed($top: auto, $right: auto, $bottom: auto, $left: auto) {
    @include position(fixed, $top, $right, $bottom, $left);
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
    @include position(absolute, $top, $right, $bottom, $left);
}

@mixin relative($top: auto, $right: auto, $bottom: auto, $left: auto) {
    @include position(relative, $top, $right, $bottom, $left);
}
