@mixin text-bold() {
    font-weight: 700;
}

@mixin text-semi-bold() {
    font-weight: 600;
}

@mixin text-light() {
    font-weight: 300;
}

@mixin text-secondary() {
    color: $stone;
}

@mixin text-disabled() {
    color: $rock;
}

@mixin text-title() {
    font-size: 20px;
    line-height: 28px;
}

@mixin text-small() {
    font-size: 14px;
    line-height: 19px;
}

@mixin text-very-small() {
    font-size: 13px;
    line-height: 20px;
}

@mixin title() {
    color: $charcoal-light;
    font-weight: 600;
}

@mixin font-osgill() {
    font-family: 'OSGill', sans-serif;
}

@mixin font-sourcesanspro() {
    font-family: 'SourceSansPro', sans-serif;
}
