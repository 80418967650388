@function map-next($map, $key) {
    $values: map-keys($map);
    $i: 0;

    @if (map-has-key($map, $key)) {
        $i: index($values, $key) + 1;
    }

    @if ($i > length($map) or $i == 0) {
        @return null;
    } @else {
        @return map-get($map, nth($values, $i));
    }
}

@function get-max-size($breakpoint, $value) {
    $max-size: map-next($breakpoints, $breakpoint);
    @if ($max-size == null) {
        $str-max: $value;
    }
    @return $max-size - 0.5;
}

@mixin breakpoint($value) {
    @if ($value == '') {
        @content;
    } @else {
        $bp: nth($value, 1);
        $direction: if(length($value) > 1, nth($value, 2), up);

        $str: map_get($breakpoints, $bp);

        @if ($str == null) {
            $str: $value;
        }

        @if $direction == 'up' {
            $prop: 'min-width';
            $str: $str;

            @media screen and ($prop: #{$str}) {
                @content;
            }
        } @else if $direction == 'down' {
            @media screen and (max-width : #{get-max-size($bp, $value)}) {
                @content;
            }
        } @else if $direction == 'only' {
            @media screen and (min-width: #{$str}) and (max-width: #{get-max-size($bp, $value)}) {
                @content;
            }
        }
    }
}

@mixin breakpoint-map($map) {
    @each $size, $props in $map {
        @include breakpoint($size) {
            @each $prop, $value in $props {
                #{$prop}: #{$value};
            }
        }
    }
}

@mixin hide-for($value) {
    @include breakpoint($value) {
        display: none;
    }
}
