@import '~styles/utils';

.tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    @include breakpoint(small down) {
        align-items: center;
    }

    &__title {
        font-weight: bold;

        @include breakpoint(medium down) {
            font-size: 20px;
        }
        @include breakpoint(medium) {
            margin-bottom: 5px;
        }
    }

    &__description {
        @include text-small();
        @include breakpoint(large) {
            font-size: $global-font-size;
            line-height: $global-line-height;
        }
    }
}
