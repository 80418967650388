@import '~styles/utils';

.product-preview-container {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__wrapper {
        position: relative;
        max-width: 80%;
        max-height: 100%;

        &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
