@import '~styles/utils';

.sidebar-scroll-area {
    @include absolute($top: -150px, $left: 0);

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 150px;
    padding-bottom: 8px;
    opacity: 0.6;
    visibility: visible;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(58, 58, 58, 0.9) 77%,
        $black
    );
    color: $white;

    @include breakpoint(medium) {
        @include absolute($top: -240px, $left: 0);

        padding-bottom: 12px;
        height: 240px;
    }

    @include breakpoint(large) {
        display: none;
    }

    &--hidden {
        display: none;
    }

    &__clickable-area {
        text-align: center;
        cursor: pointer;
    }

    &__icon {
        @include os-icon('expand');

        font-size: 24px;
        line-height: 24px;
    }

    &__text {
        @include text-small();
        @include text-semi-bold();
    }
}
