@import '~styles/utils';

.product-preview-error-message {
    @include absolute($top: 0, $left: 7px);
    width: 230px;
    height: 100%;
    background-image: url('./assets/coverDropzoneBackground.svg');
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(medium) {
        @include absolute($top: 0, $left: 15px);
        width: 510px;
    }

    @include breakpoint(xlarge) {
        width: 610px;
    }

    &--rectangle {
        width: 690px;
        top: 15px;
        height: 445px;

        @include breakpoint(large down) {
            width: 568px;
            height: 365px;
        }

        @include breakpoint(medium down) {
            left: 15px;
        }
    }

    &__container {
        position: relative;
        max-width: 260px;
    }

    &__map-icon {
        @include os-icon('map');
        font-size: 40px;
        display: block;
        text-align: center;
        color: $icon-color;

        @include breakpoint(medium) {
            font-size: 78px;
        }
    }

    &__description {
        font-size: 12px;
        text-align: center;
        color: $charcoal;

        @include breakpoint(medium) {
            font-size: 16px;
        }
    }

    &__button {
        display: inline-block;
    }
}
