@import '~styles/utils';

@mixin border-bottom() {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #e2e2e2;
}

.order-summary {
    display: flex;
    margin-top: 15px;

    @include breakpoint(small down) {
        flex-direction: column;
    }

    &__header {
        margin-right: 109px;
    }

    &__header-text {
        font-size: 32px;
        line-height: 40px;
        color: $berry;
        white-space: pre;
        margin-bottom: 40px;

        @include breakpoint(small down) {
            font-size: 30px;
        }
    }

    &__free {
        display: flex;
        align-items: center;

        @include breakpoint(medium down) {
            margin-bottom: 16px;
        }

        &__text {
            padding: 4px 6px;
            background-color: #f5f5f5;
        }
    }

    &__button {
        width: 255px;
        height: 50px;
        background-color: $morningmoss;
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: center;
        font-size: 1.5em;
        font-weight: 600;
        border-radius: 0;

        @include breakpoint(medium down) {
            width: 100%;
        }

        &__container {
            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        &:hover,
        &:active {
            background-color: #03522d;
            border-width: 0;
            box-shadow: none;
        }
    }

    &__content {
        flex-basis: 65%;
    }

    &__product-description {
        @include border-bottom();
        padding-bottom: 7px;
    }

    &__summary-checkout {
        display: flex;
        justify-content: flex-end;

        @include breakpoint(medium down) {
            flex-direction: column;
            align-items: flex-end;
        }

        @include breakpoint(small down) {
            align-items: flex-start;
        }
    }

    &__summary-price {
        @include border-bottom();
        margin-bottom: 11px;
        margin-top: 20px;
        padding-bottom: 51px;

        div {
            font-size: 32px;
        }
    }

    &__summary-quantity {
        @include border-bottom();
        margin-top: 13px;
        margin-bottom: 35px;
        padding-bottom: 11px;
    }

    &__summary-default-location-warning {
        color: #f36721;
        font-weight: bold;
        margin-bottom: 8px;
        margin-top: 8px;

        a {
            cursor: pointer;
        }
    }

    &__summary-customer-care {
        font-style: italic;
    }
}
