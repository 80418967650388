@import '~styles/utils';

.route-upload {
    padding: 15px;
    background: lighten($berry, 57%);
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__filename {
        color: $berry;
        font-size: 16px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 5px;
    }

    &__icon {
        cursor: pointer;
    }
}
