@font-face {
    font-family: 'OSGill';
    font-style: normal;
    font-weight: normal;
    src: url('~assets/fonts/OSGill-Regular.eot');
    src: url('~assets/fonts/OSGill-Regular.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Regular.woff') format('woff'), url('~assets/fonts/OSGill-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: normal;
    font-weight: 300;
    src: url('~assets/fonts/OSGill-Light.eot');
    src: url('~assets/fonts/OSGill-Light.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Light.woff') format('woff'), url('~assets/fonts/OSGill-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: normal;
    font-weight: 600;
    src: url('~assets/fonts/OSGill-Semibold.eot');
    src: url('~assets/fonts/OSGill-Semibold.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Semibold.woff') format('woff'), url('~assets/fonts/OSGill-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: normal;
    font-weight: 700;
    src: url('~assets/fonts/OSGill-Bold.eot');
    src: url('~assets/fonts/OSGill-Bold.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Bold.woff') format('woff'), url('~assets/fonts/OSGill-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: normal;
    font-weight: bold;
    src: url('~assets/fonts/OSGill-Bold.eot');
    src: url('~assets/fonts/OSGill-Bold.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Bold.woff') format('woff'), url('~assets/fonts/OSGill-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: italic;
    font-weight: normal;
    src: url('~assets/fonts/OSGill-Italic.eot');
    src: url('~assets/fonts/OSGill-Italic.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-Italic.woff') format('woff'), url('~assets/fonts/OSGill-Italic.woff2') format('woff2');
}

@font-face {
    font-family: 'OSGill';
    font-style: italic;
    font-weight: 700;
    src: url('~assets/fonts/OSGill-BoldItalic.eot');
    src: url('~assets/fonts/OSGill-BoldItalic.eot') format('embedded-opentype'),
        url('~assets/fonts/OSGill-BoldItalic.woff') format('woff'),
        url('~assets/fonts/OSGill-BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'OSIcons';
    font-style: normal;
    font-weight: normal;
    src: url('~assets/fonts/os-icons.eot');
    src: url('~assets/fonts/os-icons.eot') format('embedded-opentype'), url('~assets/fonts/os-icons.svg') format('svg'),
        url('~assets/fonts/os-icons.ttf') format('truetype'), url('~assets/fonts/os-icons.woff') format('woff');
}

@font-face {
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: 700;
    src: url('~assets/fonts/SourceSansPro-Bold.eot');
    src: url('~assets/fonts/SourceSansPro-Bold.eot') format('embedded-opentype'),
        url('~assets/fonts/SourceSansPro-Bold.woff') format('woff'),
        url('~assets/fonts/SourceSansPro-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: bold;
    src: url('~assets/fonts/SourceSansPro-Bold.eot');
    src: url('~assets/fonts/SourceSansPro-Bold.eot') format('embedded-opentype'),
        url('~assets/fonts/SourceSansPro-Bold.woff') format('woff'),
        url('~assets/fonts/SourceSansPro-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: 600;
    src: url('~assets/fonts/SourceSansPro-Semibold.eot');
    src: url('~assets/fonts/SourceSansPro-Semibold.eot') format('embedded-opentype'),
        url('~assets/fonts/SourceSansPro-Semibold.woff') format('woff'),
        url('~assets/fonts/SourceSansPro-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: 500;
    src: url('~assets/fonts/SourceSansPro-Semibold.eot');
    src: url('~assets/fonts/SourceSansPro-Semibold.eot') format('embedded-opentype'),
        url('~assets/fonts/SourceSansPro-Semibold.woff') format('woff'),
        url('~assets/fonts/SourceSansPro-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    src: url('~assets/fonts/SourceSansPro-Semibold.eot');
    src: url('~assets/fonts/SourceSansPro-Semibold.eot') format('embedded-opentype'),
        url('~assets/fonts/SourceSansPro-Semibold.woff') format('woff'),
        url('~assets/fonts/SourceSansPro-Semibold.woff2') format('woff2');
}
