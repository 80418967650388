@import '~styles/utils';

.map-style-list {
    max-width: 350px;
    padding-bottom: 30px;

    @include breakpoint(medium up) {
        width: 280px;
    }

    &__title {
        padding-bottom: 10px;
        color: $charcoal-light;
        font-size: 16px;
        font-weight: 600;
    }
}
